"use client";

export default function LoginPage() {
  return (
    <>
      <main className="flex min-h-screen max-w-[100vw] flex-col items-center justify-between">
        about page
      </main>
    </>
  );
}
